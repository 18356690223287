/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "@angular/material/theming";
@import 'sweetalert2/src/sweetalert2.scss';
@import '~handsontable/dist/handsontable.full.css';
@include mat-core();

$my-app-primary: red;

@import "assets/styles/variable.scss";
@import '~drawflow/dist/drawflow.min.css';

@font-face {
  font-family: 'Public Sans';
  src: url('assets/fonts/Public_Sans/static/PublicSans-Medium.ttf') format('truetype');

}

// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/MontSerrat/static/Montserrat-Medium.ttf') format('truetype'),
//     url('assets/fonts/MontSerrat/static/Montserrat-Bold.ttf') format('truetype');
// }

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/MontSerrat/Montserrat-VariableFont_wght.ttf') format('truetype'),
    url('assets/fonts/MontSerrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

body {
  --bg: #F5F7FD;
  --primary: #5E6DA6;
  --secondary: #E6E6E6;
  --text: red;
  --text-primary: #6E6F70;
}



html,
body {
  height: 100%;
  // background-color: #f0f3f8;
  background-color: white;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.mat-dialog-container {
  padding: 0px 0px 24px 0px !important;
  border-radius: 10px !important;
}

.mat-dialog-content {
  margin: 0px !important;
}

mat-dialog-actions {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

mat-selection-list {
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.15);
}

.mat-list-item {
  padding: 0px !important;
}

.mat-form-field-underline {
  background-color: white;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #e4e4e4 !important;
}

tr.mat-row {
  cursor: pointer !important;
}

.custom-page {
  width: 100%;
  height: auto;

  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table {
    width: 100%;
  }

  .mat-form-field {
    font-size: 14px;
    width: 100%;
  }

  td,
  th {
    width: 0%;
  }

  .avatar {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      overflow: hidden;
    }
  }
}

.mat-dialog-actions {

  // ...
  &[align="end"] {
    justify-content: flex-end;
  }

  &[align="center"] {
    justify-content: center;
  }
}

mat-form-field {
  width: 100%;
}

mat-nav-list {
  padding: 0px !important;

  .mat-list-item-content {
    padding: 0 10px;
  }
}

.icon-avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.mat-list-item-content {
  padding: 10px !important;

  .mat-list-text {
    padding-left: 5px !important;
  }
}

.filter-form-content {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;

  mat-form-field {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.filter-action-content {
  display: flex;
  justify-content: flex-end;
}

.filter-list-content {
  width: 100%;

  .list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding: 16px 0px;

    mat-icon {
      cursor: pointer;
    }
  }
}

.b-btn {
  border-radius: 10px !important;
  height: 40px;
  box-shadow: 0px 7px 15px 0 rgba(0, 0, 0, 0.22);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  text-align: center;
  color: $text-color;
  margin-left: 16px !important;
  background-color: white;
  min-width: 125px !important;
}

.b-success {
  background-color: #99dce8 !important;
}

.b-primary {
  background-color: $app-color !important;
  color: white;
}

.b-danger {
  background-color: $delete-color !important;
  color: white;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  // background: #716dff;
  background: linear-gradient(153deg, #5760ab 0%, #7fc2ec 100%);
}

.mat-input-element {
  caret-color: red !important;
}

.modal-form-container {
  margin-bottom: 16px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 7px 15px 0 rgba(0, 0, 0, 0.22);
  border-radius: 10px;

  .modal-form-content {
    display: flex;

    mat-form-field {
      margin-right: 16px;
    }
  }

  .modal-form-action-content {
    display: flex;
    justify-content: flex-end;
  }
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $app-color;
  color: #fff;
}

mat-horizontal-stepper {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 24px !important;
  box-shadow: 0px 7px 15px 0 rgba(0, 0, 0, 0.22);
  border-radius: 10px;
}

.ds-form .mat-horizontal-content-container {

  box-shadow: none !important;
}

.mat-dialog-actions {
  flex-wrap: nowrap !important;
}

.rightSideBar .mat-dialog-content,
.leftSideBar .mat-dialog-content,
.center .mat-dialog-content {
  height: 100%;
  padding: 0;
  overflow: auto;
  max-height: 100% !important;
}

.rightSideBar mat-dialog-actions,
.leftSideBar .mat-dialog-actions {
  display: none;
}

.rightSideBar .mat-dialog-container,
.center .mat-dialog-container,
.leftSideBar .mat-dialog-container {
  overflow: hidden;
  max-width: 100vw;
  padding: 0px !important;
}

.rightSideBar .dashboard-title,
.center.dashboard-title,
.leftSideBar .dashboard-title {
  display: none !important;
}

// .rightSideBar gridster,.leftSideBar gridster,.center gridster {
//   height:96% !important
// }
.rightSideBar .dashboard-header,
.leftSideBar .dashboard-header,
.center .dashboard-header {
  padding: 10px !important;
}

.fullScreen .mat-dialog-container {
  overflow: hidden !important;
}

.fullScreen .mat-dialog-content {
  height: 85%;
  max-height: none !important;
}

// .mat-dialog-content{
//   max-height: none !important;
// }
.fullScreen .mat-dialog-actions {
  height: 10%;
}

.rightSideBar,
.leftSideBar,
.center {
  max-width: 100vw !important;
}

.modal-header {
  padding: 14px !important;
  // position: absolute;
}

.rightSideBar .filter-contentx,
.leftSideBar .filter-contentx,
.center .filter-contentx {
  top: -70px !important;
  position: relative !important;
}

.rightSideBar .filter-content,
.leftSideBar .filter-content,
.center .filter-content {
  top: 0px !important;
}

.rightSideBar .modal-header,
.center .modal-header,
.leftSideBar .modal-header {
  position: absolute;
  right: 1%;
  top: 1%;
  // cursor: pointer;
}

.captcha-container {
  box-shadow: none !important;
}

// .captcha-actions input[type=button]{
//   display: none !important;
//   }

// .captcha-actions input[type=text] {
//   width: 75%;
// }
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .leftSideBar {
    width: 100% !important;
  }

  .rightSideBar {
    width: 100% !important;
  }

  .modal-header {
    right: 0% !important;
  }

}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  visibility: var() !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  display: flex;
  flex-direction: row-reverse !important;
}

.chatbotmodal .mat-dialog-container {
  overflow: hidden !important;

}

.btn-disable {
  color: grey !important;

}

button.mat-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.mat-menu-item.mat-menu-item .mat-icon {
  margin-right: 0px !important;
}

.dash-dialog {

  max-height: none !important;
}

@media (max-width: 1020px) {

  .toolbar img:nth-child(1) {
    // width: 10% !important;
  }

  .toolbar {
    height: 5vw !important;
  }

  .home-container {

    height: calc(100% - 5vw) !important;

  }

  mat-dialog-container .grid-container {
    height: calc(-5vw + 100vh) !important;
  }
}



.mat-mini-fab .mat-button-wrapper {

  display: contents !important;
  color: white;

}

@media (max-width: 820px) {

  .toolbar {
    height: 5vh !important;
  }

  .home-container {

    height: calc(100% - 5vh) !important;

  }

  .responsive-modal {
    width: 80% !important;
  }


  .exit-button {
    left: calc(85% + 13px) !important;
  }

  .user-modal-footer {
    width: 80% !important;
  }

  .top-button {
    display: block !important;
    margin-bottom: 1rem;


  }

  .toolbar img:nth-child(1) {
    // width: 15% !important;
  }

  .lef-toggle-button {
    display: none !important;
  }
}

@media (min-width: 640px) and (max-width: 780px) {
  .widget-container .widget-header {
    // padding: 10px 15px !important;
  }

  .widget-container .widget-header .title {
    font-size: calc(18px - 0.7vw) !important;

    width: 100%;
    height: 4vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

  }

  .toolbar {
    img {
      width: 30px;
    }
  }

}

@media (max-width: 480px) {
  #message-body {
    font-size: x-small;
  }

  .list-container {
    height: 60vh;
    overflow: auto;
  }

  .button-group button {
    font-size: x-small !important;
    width: 12vh !important;
  }

  .bd-container {
    overflow: auto;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-height: 100vh !important;
    height: 100vh !important;
  }

  .designer-container {
    width: auto !important;
    padding: 5px;
  }

  .button-group {
    width: 100%;
    justify-content: space-between !important;
    flex-wrap: wrap;
  }

  .mat-mini-fab .mat-button-wrapper {

    display: contents !important;
    color: white;

  }

  .responsive-modal {
    width: 90% !important;
  }

  .chat-dialog-container {
    width: 100% !important;
    height: 100% !important;
  }

  .exit-button {
    left: calc(85% + 13px) !important;
  }

  .user-modal-footer {
    width: 90% !important;
  }

  .responsive-table .table-row {
    max-height: 5rem !important;

  }


  .table-header {
    display: none !important;
  }

  .table-row {
    height: 5rem;
    flex-wrap: wrap;
  }

  // .data-list-avatar {
  //   position: absolute;
  //   left: 70%;

  // }

  .list-container {
    margin-top: 35% !important;
  }

  .fixed-top {
    position: fixed;
    width: 90%;
  }

  .respnsive-search {
    flex-basis: 90% !important;
  }

  .top-button {
    display: block !important;
    margin-bottom: 1rem;


  }

  .lef-toggle-button {
    display: none !important;
  }

  .selfservice-main {
    margin-left: 0px !important;
  }

  .table-row div:nth-child(1) {
    flex-basis: 10% !important;
    // margin-bottom: 1rem;
  }

  .table-row div:nth-child(2) {
    flex-basis: 80% !important;
    // margin-bottom: 0.3rem;

  }

  .table-row div:nth-last-child(4) {
    flex-basis: 80% !important;
  }

  // .table-row div:nth-last-child(1) {
  //   flex-basis: 90% !important;
  // }
  // .table-row div:nth-last-child(2) {
  //   flex-basis: 10% !important;
  // }
  .add-dashboard-button {
    font-size: 0.6rem;
  }
}






.top-button {
  display: none;
}


.user-box input:-webkit-autofill,
.user-box input:-webkit-autofill:hover,
.user-box input:-webkit-autofill:focus,
.user-box textarea:-webkit-autofill,
.user-box textarea:-webkit-autofill:hover,
.user-box textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;

}


.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: rgba(98, 112, 177, 1) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(98, 112, 177, 0.3) !important;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.grid-container {
  height: 70vh;
}


.widget-header {
  .title {
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}


@media (max-width: 480px) and (orientation: landscape) {
  .toolbar {
    height: 10vh !important;
  }

}

// @media screen and (min-width:320px) and (max-width:736px) {
//   .cdk-overlay-pane {
//     width: 100% !important;
//   }

// }


@media screen and (min-width:415px) and (max-width:736px) {
  .toolbar {
    height: 10% !important;

  }

}

@media only screen and (max-width: 600px) {
  // gridster {
  //   height: calc(100% - 35px) !important;
  // }

  .message-container {
    justify-content: flex-start !important;
  }
}

@media screen and (max-device-aspect-ratio: 4/3) {
  // gridster {
  //   height: 95% !important;
  // }

  .chat-dialog-container {
    width: 100% !important;
    height: 100% !important;
  }
}


.list-container {
  /* Kaydırma çubukları için özel stiller */
  scrollbar-width: thin;
  /* Kaydırma çubuklarının kalınlığını ayarla */
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    width: 0px;
    /* Kaydırma çubuklarının genişliğini ayarla */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Kaydırma çubuklarının arka plan rengini ayarla */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    /* Kaydırma çubuklarının iz sürme arka plan rengini ayarla */
  }




}

.mat-button-disabled {
  color: gray !important;
}

@media screen and (max-device-aspect-ratio: 4/3) {

  /* Bu stil kuralları yalnızca mobil cihazların yatay (landscape) modunda çalışacak */
  .toolbar img:nth-child(1) {
    // width: 5rem !important;
  }
}

.custom-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  display: contents !important;
}

.custom-form-field .mat-icon-button {
  height: 2.5em !important;
}

//burası neden var
// .custom-form-field.mat-button .mat-button-wrapper>*, .mat-flat-button .mat-button-wrapper>*, .mat-stroked-button .mat-button-wrapper>*, .mat-raised-button .mat-button-wrapper>*, .mat-icon-button .mat-button-wrapper>*, .mat-fab .mat-button-wrapper>*, .mat-mini-fab .mat-button-wrapper>*{
//   // width: 16px !important;
// }

// .badgemenu .mat-menu-content{
//   width: 165px;
// }


.badgemenu .mat-menu-content {
  background-color: rgb(242, 242, 242) !important;
  max-height: 50vh !important;
}

.views-menu {
  width: 400px !important;
  max-width: none !important;
}

// .cdk-overlay-pane{
//   max-height: 50vh !important;
// }

// input{
//   -webkit-text-fill-color:black !important;
// }

.overflow-hidden {
  mat-dialog-container {
    overflow: hidden !important;
    padding: 0px 0px 0px 0px !important;
  }

  .dialog-body {
    height: calc(100% - 90px) !important;
  }
}

.overflow-auto {
  mat-dialog-container {
    overflow: auto !important;
  }
}

.widget-area {

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: green !important;
  }
}

.viewsModal mat-dialog-container {
  border-radius: 0 !important;
}



//Responsive ayarlar için
@media screen and (max-width: 767px) {
  /* Küçük ekranlar için stil kurallarınızı burada tanımlayabilirsiniz. */
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  /* Orta ekranlar için stil kurallarınızı burada tanımlayabilirsiniz. */

}

// @media screen and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape),
// screen and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) 

//Landscape(yatay) tablet modu (ipad pro hariç)
@media screen and (min-width: 768px) and (max-width: 1184px) and (orientation: landscape) {

  /* iPad landscape için stil kurallarınızı burada tanımlayabilirsiniz. */
  .widget-container .widget-header {
    padding: 5px 10px !important;
    line-height: 0.8rem !important;
    // height: 25%;
  }

  .no-subtitle .widget-header {
    height: auto !important;
  }

  .no-subtitle .widget-content {
    // height: 78% !important;
  }

  .widget-container .widget-content {
    // height: 74% !important;
  }

  .widget-header .title span {
    font-size: 0.8rem !important;
  }

  .widget-container .widget-header .sub-title {
    font-size: 0.5rem !important;
    color: gray !important;
  }

  .widget-action mat-icon {
    transform: rotate(90deg);
  }
}


@media screen and (orientation: landscape) {
  /* Yatay moda özgü stil kurallarınızı burada tanımlayabilirsiniz. */
}

@media screen and (orientation: portrait) {
  /* Dikey moda özgü stil kurallarınızı burada tanımlayabilirsiniz. */
}



@media screen and (min-width: 1024px) {
  /* Büyük ekranlar için stil kurallarınızı burada tanımlayabilirsiniz. */
}

.selfservicemodal mat-dialog-content {
  max-height: 80vh !important;
}



.selected-item {

  .mat-checkbox-frame {
    border-color: white !important;
  }
}

.custom-primary-button {
  background-color: #5E6DA6;
  color: white;
  border-radius: 5px !important;

  mat-icon {
    transform: scale(0.8);
  }

}

.custom-primary-stroked-button {
  color: #5E6DA6;
  border-radius: 5px !important;
  border: 1.4px solid #5E6DA6 !important;
  display: flex;
  align-items: center;
}

.gray-input {
  height: 25px;
  border: none;
  background-color: rgba($color: #D9D9D9, $alpha: 0.56);
  border-radius: 5px;
  padding-right: 5px;
  padding-left: 10px;
  // width: 40%;
}

.h-35 {
  height: 35px;
}

.mb-10 {
  margin-bottom: 10px !important;
}


.mini-action-button {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding-right: 8px;
}

.mini-action-button:hover {
  background-color: rgba($color: #000000, $alpha: 0.1);

}

.mini-action-button mat-icon {
  transform: scale(0.5);
}


.swal2-html-container {
  font-weight: 200 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;

}

.cdk-overlay-container {
  z-index: 100 !important;
}


@media (max-width: 991px) {
  .dashboard-button-groups-container {
    display: none !important;
  }

  .filterAI-content {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .example-sidenav {
    width: 100% !important;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #f0f0f0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;

  }

  .grid-container {
    height: calc(-125px + 100vh) !important;
  }


  .mobile-ai {
    padding: 10px;
    display: flex !important;

    .aifilter-container {
      width: 100% !important;
      height: 30px;
    }
  }


  .mat-button.mat-primary,
  .mat-icon-button.mat-primary,
  .mat-stroked-button.mat-primary {
    color: #6076BA !important;
  }

  //     .footer button {
  //         margin: 5px;
  //     }


}



.custom-gray-input {
  height: 35px;
  border: none;
  background-color: rgba($color: #D9D9D9, $alpha: 0.56) !important;
  border-radius: 5px;
  width: 100%;
  padding-left: 5px !important;
}





.mr-10 {
  margin-right: 10px !important;
}

.bg-light-danger {
  background: rgba(234, 84, 85, .12) !important;
}

.bg-light-info {
  background: rgba(115, 103, 240, .12) !important;

}

.bg-light-success {
  background: rgba(40, 199, 111, .12) !important;

}

//drawflow stilleri

.drawflow .drawflow-node {
  display: flex !important;
  background: rgba(255, 255, 255, 1) !important;
  color: #000000 !important;
  border: 1px solid #5E6DA6 !important;
  border-radius: 5px !important;
  min-height: 100px !important;
  width: auto !important;
  min-width: 260px !important;
  padding-top: 3px;
  padding-bottom: 3px;

  // -webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}


.custom-draw-flow-card {
  background-color: transparent !important;
  background: transparent !important;
  width: 100px;
}

.drawflow {
  .custom-card-nodeid {
    position: absolute;
    top: 3px;
    right: 16px;
    background-color: rgba(94, 109, 166, 0.39);
    border-radius: 5px;
    padding: 2px;
    color: white;
  }

  #contextmenu {
    border: none;
    border-radius: 5px;
    box-shadow: rgba(35, 30, 54, 0.24) 0px 3px 8px;
    min-width: 100px;


    .contextmenu-container {
      display: flex;
      flex-direction: column;

      button:hover {
        background-color: rgba($color: #5E6DA6, $alpha: 0.2);
        color: #5E6DA6;
      }

      button {
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        display: flex;
        align-items: center;

        // justify-content: center;
        svg {
          width: 14px !important;
          height: 14px !important;
          position: relative;
        }

        span {
          margin-left: 5px;
        }

      }
    }
  }


  .drawflow-node .input {
    left: -23px;
    border: 1 solid gray;
    border-radius: 50px;
    height: 10px;
    width: 10px;
  }

  .drawflow-node .output {
    right: -8px;
    border: 1 solid gray;
    border-radius: 50px;
    height: 10px;
    width: 10px;
  }


  .custom-card-container {
    border: 1.5px solid #5E6DA6;
    border-radius: 5px;
    padding: 20px;
    font-family: "Montserrat", sans-serif;


    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-info {
        display: flex;
        align-items: center;
      }

      .card-info {
        display: flex;
        margin-left: 5px;
        flex-direction: column;

      }

      .card-img {
        img {
          width: 35px;
          height: 35px;
        }
      }

      .action-area {
        color: #5E6DA6;
        display: flex;

        svg {
          position: relative !important;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }


      }

    }

    .bottom-section {
      .bottom-text {
        line-height: 2;
        display: flex;
        flex-direction: column;

        .bottom-title {
          font-weight: bold;
        }

        .small-text {
          font-size: large;
          line-height: 1.3;
          overflow-wrap: break-word;
          color: gray;
          max-width: 350px;

          max-height: 70px;
          /* text-overflow: ellipsis; */
          overflow: hidden;
          text-overflow: ellipsis;
          /* white-space: nowrap; */
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

    }
  }




}

#contextmenu {
  display: flex;
  align-items: center;
  position: absolute;
  background: white;
  border: 2px solid black;
  padding: 10px;
  z-index: 10;

}


.padding-0-modal .mat-dialog-container {
  padding: 0 !important;
}






.overlay-box {
  position: absolute;
  border: 1px solid #000;
  padding: 5px;
  position: absolute;
  z-index: 1000;
}

body.swal2-height-auto {
  min-height: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-gray-input {
  .mat-select-trigger {
    height: 100% !important;
  }
}


.no-box-shadow .mat-horizontal-content-container {
  box-shadow: none !important;

  .data-table-content {
    padding: 0 !important;
  }
}


.form-body {
  .content-area {
    .mat-tab-list {
      transform: translateX(0px);
      background-color: rgb(234, 234, 234);
      border-radius: 5px;
    }
  }
}


.with-action-area {
  mat-dialog-container {
    overflow: hidden !important;
    padding: 0px 0px 0px 0px !important;
  }

  .dialog-body {
    height: calc(100% - 160px) !important;
  }
}


gridster {
  #widget-id {
    background-color: #888;
  }
}


.color-type-select {
  .mat-select-trigger {
    padding-left: 5px;
  }


}

.color-type-option {
  .mat-option-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.mat-button-toggle-group {

  height: 25px;
  align-items: center;

  .mat-button-toggle {
    width: 50%;
  }
}

.selected-color {
  border: 2px solid #61C478;
}

.sm-button {
  width: 30px;
  height: 30px;
  padding: 0px 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.no-scroll {
  overflow: hidden !important;
}


.expand-table {
  .mat-expansion-panel-body {
    padding-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
  }
}


.min-w-40 {
  width: 40vw;

  mat-selection-list {
    box-shadow: none;
  }
}


.splash-progress circle {
  stroke-linecap: round;
}


.mat-progress-spinner circle {
  stroke: rgb(97, 127, 184);
  /* Tüm çemberin rengini gri yapar */
}



.font-md {
  font-size: medium !important;
}

.font-sm {
  font-size: small !important;
}

.font-xs {
  font-size: x-small !important;
}

.font-lg {
  font-size: large !important;
}


.calculatedselect .mat-select-arrow-wrapper {
  display: none;
}


.w-150 {
  width: 150px;
}


// .mat-icon {
//   height: auto !important;
// }

.date-range-field .mat-form-field-infix {
  display: flex;
  margin: 0;
  padding: 0;
  border: none;
}

// span img,
// svg {
//   vertical-align: unset !important;
// }

// button .mat-icon{
//   height: none;
// }



.right-modal {
  // position: fixed;
  // // top: 25vh;
  // right: 0;
  // // width: 50vw !important;
  // // height: 50vh !important;
  // max-height: none;
  // margin: 0;

  mat-dialog-container {
    border-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    padding: 0px !important;
  }
}


.perf-list-item .media-heading {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.perf-list-item .media-body {

  width: 70%;
}

.perf-list-item .notification-text {
  color: #3d3c3c !important;
}

.button {
  mat-icon {
    width: 16px;
    height: 100%;
  }
}


.dashboard-button-groups {
  height: 40px;
}




.chat-container {

  background-color: white;
  bottom: 55px;
  position: absolute !important;
  right: 30px;
  border-radius: 20px;
  border: 2px solid var(--primary);

  mat-dialog-container {
    border-radius: 20px !important;
    overflow: hidden;
  }
}





/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .chat-container {
    width: 100% !important;
    height: 100% !important;
    right: 0;
    bottom: 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .chat-container {
    width: 100% !important;
    height: 100% !important;
    right: 0;
    bottom: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .chat-container {
    width: 60% !important;
    height: 80% !important;

  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .chat-container {
    width: 60% !important;
    height: 80% !important;

  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Stil kuralları */
}



.mobileText {
  .title {
    height: 15px;

    span {
      padding: 0px;
      font-size: 10px !important;
      font-weight: 400;
      color: #070f29;
      letter-spacing: 0.54px;
      font-family: ProximaNova;
    }

    mat-icon {
      transform: scale(0.7);
    }
  }

  .sub-title {
    padding: 0px !important;
    font-size: 8px !important;
    font-weight: 400 !important;
    color: #070f29 !important;
    font-style: italic !important;
    margin-top: -5px;
  }

  .widget-header[_ngcontent-veq-c402] {
    padding: 10px 10px;
  }
}


.context-menu-elements {
  .mat-menu-item {

    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    font-family: Montserrat;
    font-size: small;

    mat-icon {
      transform: scale(0.8);
      margin-right: 5px !important;
    }
  }

}


.download-options {
  .downloadfiltercheckbox {
    font-size: smaller;
    align-items: center;
    display: flex;
    margin-left: 10px;
    margin-top: 0;
  }


}


.maxh-40 {
  max-height: 40vh !important;
}

